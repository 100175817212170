<template>
  <div>
    <div class="flex flex-grow justify-end mb-2">
      <Button
        text="Reset to Benchmarks"
        type="secondary"
        size="medium"
        class="mr-2"
        :disabled="disableReset"
        :onClick="resetBenchmarks"
        data-testid="benchmarks-button"
      />
      <Button
        text="Save Progress"
        type="secondary"
        size="medium"
        :onClick="saveProgress"
        data-testid="save-button"
      />
    </div>
    <div
      class="flex flex-row w-full items-center bg-grey-light-3 py-1 px-2 rounded"
      :class="mappedList.length < 10 ? '' : 'pr-2'"
    >
      <div
        class="flex flex-row items-center focus:outline-none w-tableIndex mr-1"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
      </div>
      <div class="flex flex-grow max-w-1/4">
        <div class="flex flex-row items-center focus:outline-none">
          <p class="text-sm-2 text-grey-light mr-0.4">COMPETENCY</p>
        </div>
      </div>

      <button
        v-if="slicingIndex > 0"
        class="bg-white h-btn w-30 rounded flex justify-center items-center mr-5"
        @click="decreaseSlice"
        data-testid="left-button"
      >
        <img :src="arrow" class="transform rotate-180" />
      </button>
      <div v-else class="w-30 mr-5" />
      <div
        v-if="isOrganizational"
        class="flex flex-col w-150 mr-5"
      >
        <div
          class="flex flex-row items-center justify-center focus:outline-none"
        >
          <p
            class="text-center text-sm-2 text-grey-light mr-0.4 min-h-32 flex items-center"
          >
            ORGANIZATION
          </p>
        </div>
        <div class="flex mt-1.5">
          <p class="text-grey-dark-2 text-sm w-75 text-center">
            Professional Benchmark
          </p>
          <p class="text-grey-dark-2 text-sm w-75 text-center">
            Company Target
          </p>
        </div>
      </div>
      <div
        v-if="isIndividual"
        v-for="item in slicedColumns"
        :key="item.targetId"
        class="flex flex-col w-150 mr-5"
      >
        <div
          class="flex flex-row items-center justify-center focus:outline-none"
        >
          <p
            class="text-center text-sm-2 text-grey-light mr-0.4 min-h-32 flex items-center"
          >
            {{ item.jobRoleName }}
          </p>
        </div>
        <div class="flex mt-1.5">
          <p class="text-grey-dark-2 text-sm w-75 text-center">
            Professional Benchmark
          </p>
          <p class="text-grey-dark-2 text-sm w-75 text-center">
            Company Target
          </p>
        </div>
      </div>

      <button
        v-if="slicingIndex * 3 < headerColumns.length - 3"
        class="bg-white h-btn w-30 rounded flex justify-center items-center"
        @click="increaseSlice"
        data-testid="right-button"
      >
        <img :src="arrow" />
      </button>
    </div>
    <div>
      <VirtualList
        style="max-height: 360px; overflow-y: auto"
        :data-key="'competencyId'"
        :data-sources="mappedList"
        :data-component="itemComponent"
        :extra-props="{
          toggle: toggleItem,
          onChange: onChange,
          slicingIndex: slicingIndex,
          jobRoles: this.jobRoles,
          disableAll,
          toggleDisabled,
        }"
        data-testid="targets-table"
      />
    </div>
  </div>
</template>

<script>
import arrow from '@/assets/img/icons/arrow-right.svg';
import Button from '@/components/Button/Button.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import ListItem from './ListItem';
import VirtualList from 'vue-virtual-scroll-list';

export default {
  name: 'TargetsTable',
  components: { VirtualList, Button },
  data: () => ({
    disableReset: true,
    disableAll: false,
    mappedList: [],
    headerColumns: [],
    itemComponent: ListItem,
    slicingIndex: 0,
    arrow,
  }),
  props: { handler: { type: Function } },
  async mounted() {
    await this.getParticipants({
      id: this.id,
      pageNr: 1,
      pageSz: 999,
      keyword: null,
      sorting: null,
    });
    if (this.isIndividual) await this.getIndividualTargets({ id: this.id });
    if (this.isOrganizational) await this.getOrganizationalTargets({ id: this.id });
    await this.getCompetencyLevels({ id: this.id });
    this.mappedList = JSON.parse(JSON.stringify(this.targets));
    this.mappedList.map((item) =>
      item.targets.map((e) => (e.value = e.value.toFixed(2)))
    );
    this.headerColumns = this.mappedList[0].targets.filter(
      (el) => this.jobRoles.indexOf(el.jobRoleId) > -1
    );
  },
  computed: {
    ...mapGetters({
      jobRoles: 'assessmentSetup/getAssignedJobRolesIds',
    }),
    ...mapState({
      targets: (state) => state.assessmentSetup.targets,
      id: (state) => state.assessmentSetup.cloneId,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    isIndividual() {
      return this.generalInfo?.surveyType === 0;
    },
    isOrganizational() {
      return this.generalInfo?.surveyType === 1;
    },
    canResetBenchmarks() {
      return this.mappedList == this.targets;
    },
    slicedColumns() {
      return this.headerColumns.slice(
        this.slicingIndex * 3,
        this.slicingIndex * 3 + 3
      );
    },
  },
  watch: {
    mappedList(val) {
      this.handler(val);
      val.map((item) =>
        item.targets.map((el) => {
          if (el.originalValue !== el.value) {
            this.disableReset = false;
          }
        })
      );
    },
    targets(val) {
      this.mappedList = val;
    },
  },
  methods: {
    ...mapActions({
      getIndividualTargets: 'assessmentSetup/getIndividualTargets',
      getOrganizationalTargets: 'assessmentSetup/getOrganizationalTargets',
      getCompetencyLevels: 'assessmentSetup/getCompetencyLevels',
      updateTargets: 'assessmentSetup/updateTargets',
      resetTargetsToBenchmarks: 'assessmentSetup/resetTargetsToBenchmarks',
      getParticipants: 'assessmentSetup/getParticipants',
    }),
    onChange(compIndex, jobRoleId, val) {
      this.disableReset = false;
      this.mappedList[compIndex].targets.find(
        (el) => el.jobRoleId === jobRoleId
      ).value = val.toFixed(2);
    },
    async saveProgress() {
      var list = JSON.parse(JSON.stringify(this.mappedList));
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < list[i].targets.length; j++) {
          if (typeof list[i].targets[j].value == 'string') {
            list[i].targets[j].value = parseFloat(list[i].targets[j].value);
          }
        }
      }
      await this.updateTargets({
        id: this.id,
        targets: list,
        surveyType: this.generalInfo?.surveyType,
      });
    },
    async resetBenchmarks() {
      this.disableReset = true;
      await this.resetTargetsToBenchmarks({ 
        id: this.id, 
        surveyType: this.generalInfo?.surveyType 
      });
      this.mappedList = this.targets;
    },
    toggleItem(index) {
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
    },
    toggleDisabled(val) {
      this.disableAll = val;
    },
    increaseSlice() {
      this.slicingIndex++;
    },
    decreaseSlice() {
      this.slicingIndex--;
    },
  },
};
</script>
